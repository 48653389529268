import { useAuth0 } from '@auth0/auth0-react'
import { Center } from '@chakra-ui/react'
import { NextPage } from 'next'
import { FC, useCallback } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'

import { Loading } from '@/ui/loading/loading'

import { LoginForm } from '@/features/admin/login/login-form'
import { Redirect } from '@/features/auth/redirect/redirect'

const LoginPage: NextPage = () => {
  return <LoginContainer />
}
export default LoginPage

const LoginContainer: FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  const login = useCallback<Props['login']>(async () => {
    await loginWithRedirect({
      // MEMO: auth0からリダイレクトしてきたページに「戻る」で遷移するとおかしくなるので、一旦トップページに遷移し、トップページ経由で企業一覧ページに飛ばす
      redirectUri: `${window.location.origin}`,
    })
  }, [loginWithRedirect])

  if (isLoading) {
    return <Loading description={{ type: 'loadingAuth' }} full />
  }

  if (isAuthenticated) {
    //  ログイン状態の時にURL直打ちで`/login`に来たときの対応
    //  ふつうにログインした直後のリダイレクトははauth0がやってくれる
    return <Redirect redirectTo={pagesPath.companies.$url()} />
  }

  return <Login {...{ login }} />
}

type Props = {
  login: () => Promise<void>
}
export const Login: FC<Props> = ({ login }) => (
  <Center h="100vh">
    <LoginForm {...{ login }} />
  </Center>
)
