import { Text, Flex, Heading } from '@chakra-ui/react'
import { FC } from 'react'

import { Button } from '@/ui/button/button'

export type Props = {
  login: () => Promise<void>
}

export const LoginForm: FC<Props> = ({ login }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    boxShadow="md"
    borderRadius="md"
    mb={8}
    py={8}
    px={16}
    width="100%"
    maxWidth={388}
  >
    <Heading textAlign="center" fontSize="2xl" mb={4}>
      ForBizAdmin
    </Heading>
    <Button colorType="primary" onClick={login} mb={4}>
      ログイン
    </Button>
    <Text>外部サービスに遷移します</Text>
  </Flex>
)
